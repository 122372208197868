import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext/context';
import { routes } from './routes-constants';

function RequireAuth ({
  children,
  allowedRoles
}: {
  children: JSX.Element;
  allowedRoles: string[];
}) {
  const { isAuthenticated, user, isValidating } = useAuth();
  const location = useLocation();
  const { index, login } = routes.authorization;

  if (isValidating) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Navigate to={`/${index}/${login}`} state={{ from: location }} replace />
    );
  }

  if (allowedRoles.indexOf(user?.role as string) === -1 && user) {
    return (
      <Navigate
        to={`/${routes[user.role].dashboard}`}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export default RequireAuth;
