import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ChildrenProps } from '../typings';

const primary = {
  background: '#90e4f7',
  button: {
    background: {
      primary: '#3c7cf1',
      secondary: '#ff3f14',
      action: '#14ad0e'
    },
    color: {
      primary: '#e1e6ef',
      secondary: '#fff',
      action: '#fff'
    }
  },
  paddings: {}
};

const ThemeProvider: React.FC<ChildrenProps> = ({ children }) => (
  <StyledThemeProvider theme={primary}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
