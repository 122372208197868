import React, { useCallback, useState } from 'react';
import AppAlertContext from './context';
import styles from './styles.module.css';
import { BigActionButton } from '../components';
import { useNavigate } from 'react-router-dom';

interface AppAlertProviderProps {
  children: React.ReactNode;
}

function AppAlertProvider ({ children }: AppAlertProviderProps) {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [homepage, setHomepage] = useState(false);
  const navigate = useNavigate();

  const handleAlertClose = () => {
    setAlertVisible(false);
    setAlertMessage('');
  };

  const goToHomapage = () => {
    handleAlertClose();
    navigate('/');
    window.scrollTo(0, 0);
  };

  const alert = useCallback((message: string, homepage = false) => {
    setAlertVisible(true);
    setAlertMessage(message);
    setHomepage(homepage);
  }, []);

  return (
    <AppAlertContext.Provider value={{ alert }}>
      {children}
      {alertVisible && (
        <div className={styles.alert}>
          <div className={styles.alertModal}>
            <p className={styles.message}>{alertMessage}</p>
            {homepage
              ? (
                <BigActionButton onClick={goToHomapage}>
                  Go to homepage
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http:www.w3.org/2000/svg'
                  >
                    <use href='/assets/icons/arrow.svg#arrow' />
                  </svg>
                </BigActionButton>
                )
              : (
                <BigActionButton onClick={handleAlertClose}>
                  Close
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http:www.w3.org/2000/svg'
                  >
                    <use href='/assets/icons/arrow.svg#arrow' />
                  </svg>
                </BigActionButton>
                )}

            <svg
              onClick={handleAlertClose}
              className={styles.closeModal}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http:www.w3.org/2000/svg'
            >
              <use href='/assets/icons/close.svg#close' />
            </svg>
          </div>
        </div>
      )}
    </AppAlertContext.Provider>
  );
}

export default AppAlertProvider;
