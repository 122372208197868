import styled from 'styled-components';

export const ActionPrimaryButton = styled.button`
  color: var(--main-black);
  font-weight: 600;
  border-radius: 20px;
  background-color: var(--main-green);
  border: 1px solid var(--main-green);
  line-height: 110%;
  letter-spacing: -0.04em;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;

  &:hover {
    background-color: var(--main-yellow);
  }
`;

export const ActionSecondaryButton = styled.button`
  color: var(--main-green);
  font-weight: 600;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid var(--main-green);
  line-height: 110%;
  letter-spacing: -0.04em;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;

  &:hover {
    color: var(--main-yellow);
  }
`;

export const BigActionButton = styled.button`
  color: var(--main-black);
  font-weight: 600;
  border-radius: 50px;
  background-color: var(--main-green);
  line-height: 110%;
  letter-spacing: -0.04em;
  font-size: clamp(20px, 5.5vw, 24px);
  font-weight: 600;
  padding: 25px clamp(25px, 6.9vw, 40px);
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    background-color: var(--main-yellow);
  }
`;

export const EyeButton = styled.button`
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
`;
