import { createContext, useContext } from 'react';

export const AppAlertContext = createContext<{
  alert:(message: string, homapage?: boolean) => void;
    }>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      alert: (message: string, homapage?: boolean) => null
    });

export function useAlert () {
  return useContext(AppAlertContext);
}

export default AppAlertContext;
