import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useAlert } from '../AlertContext/context';

import AuthContext from './context';
import {
  loginAPIRequest,
  recoverPasswordApiRequest,
  resetPasswordApiRequest,
  whoAmIRequestAPI
} from '../services/auth';

import {
  AuthenticationErrorResponse,
  LoginRequestProps,
  ResetPasswordCredentials,
  User
} from '../typings';
import { routes } from '../router/routes-constants';

export function AuthProvider ({ children }: { children: React.ReactNode }) {
  const { alert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const navigate = useNavigate();

  const login = async (credentials: LoginRequestProps) => {
    setIsLoading(true);
    try {
      const { authorization, error } = await loginAPIRequest(credentials);
      if (error) {
        throw error;
      }
      localStorage.setItem('token', authorization);
      const user: User = jwtDecode(authorization);
      setIsAuthenticated(true);
      setUser(user);
      const { index, dashboard } = routes[user.role];
      navigate(`/${index}/${dashboard}`, { replace: true });
    } catch (error) {
      const err = error as AuthenticationErrorResponse;
      if (typeof err === 'object') {
        alert(err.message);
        return;
      }
      alert(err);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = useCallback(() => {
    setIsValidating(false);
    setUser(null);
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/', { replace: true });
  }, [navigate]);

  const sendPasswordRecover = (email: string) => {
    recoverPasswordApiRequest(email)
      .then(({ success, reason }) => {
        if (!success) {
          alert(reason);
          return;
        }
        alert('You requested password change. You will receive email with further instructions');
      })
      .catch(error => alert(typeof error !== 'string' ? 'Request failed' : error));
  };

  const createNewPassword = (credentials: ResetPasswordCredentials) => {
    resetPasswordApiRequest(credentials)
      .then((success) => {
        if (success) {
          const { index, login } = routes.authorization;
          navigate(`/${index}/${login}`, { replace: true });
        }
      })
      .catch(error => alert(typeof error !== 'string' ? 'Request failed' : error));
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: any = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      if (Date.now() > expirationTime) {
        logout();
      } else {
        whoAmIRequestAPI()
          .then((data) => {
            setUser({
              ...decodedToken,
              name: data.name
            });
            setIsAuthenticated(true);
          })
          .catch(() => logout())
          .finally(() => setIsValidating(false));
      }
    } else {
      setIsValidating(false);
    }
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        login,
        logout,
        isLoading,
        isValidating,
        sendPasswordRecover,
        createNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
