export type LoginRequestProps = {
  email: string;
  password: string;
}

export type ResetPasswordCredentials = {
  token: string;
  password: string;
}

export enum Roles {
  'admin' = 'admin',
  'user' = 'user',
}

export type User = {
  userId: number;
  name: string;
  role: Roles;
};

export type AuthenticationErrorResponse = string | {
  message: string;
  path: string;
};

export type AuthenticationResponse = {
  error: AuthenticationErrorResponse;
  authorization: string
};

export type RecoverPasswordResponse = {
  error?:string;
  data: {
    success: boolean;
    reason: string;
  }
}
export type ResetPasswordResponse = {
  error?:string;
  success?: boolean;
}
export type ApplyUserCredentials = {
  fullname: string;
  email: string;
  phone: string;
}

export type ApplyUserResponse = {
  error?:string;
  data: {
    success: boolean;
    reason: string;
  }
}
export type WhoAmIResponse = {
  error?: string;
  data: {
    name: string;
  }
}
