import React, { useRef, ReactNode } from 'react';
import styles from './Input.module.css';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  icon?: ReactNode;
}

export const Input: React.FC<InputProps> = ({ label, value, onChange, icon, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    inputRef.current?.focus();
  };
  return (
    <div
      className={`${styles.inputGroup} ${value ? styles.notEmpty : ''}`}
      data-label={label}
      onClick={onClick}
    >
      <input
        ref={inputRef}
        value={value}
        onChange={onChange}
        className={styles.inputField}
        {...rest}
      />
      {icon && (
        <div className={styles.icon}>
          {icon}
        </div>
      )}
    </div>
  );
};
