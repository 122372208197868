import Routing from './router/Routing';
import AppProvider from './AppProvider';

const App = () => (
  <AppProvider>
    <Routing />
  </AppProvider>
);

export default App;
