import React, { useRef, useState } from 'react';
import { EyeButton } from '../Buttons';
import styles from './Input.module.css';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const PasswordInput: React.FC<InputProps> = ({ label, value, onChange, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    const input = inputRef.current;
    if (input) {
      const position = input.selectionStart || 0;
      input.type = showPassword ? 'password' : 'text';
      input.setSelectionRange(position, position);
      input.focus();
    }
  };

  const onClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div
      className={`${styles.inputGroup} ${value ? styles.notEmpty : ''}`}
      data-label={label}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        <input
          className={styles.inputField}
          type={showPassword ? 'text' : 'password'}
          ref={inputRef}
          value={value}
          onChange={onChange}
          {...rest}
        />
      </div>
      <EyeButton onClick={togglePasswordVisibility}>
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http:www.w3.org/2000/svg'
        >
          <use href='/assets/icons/eye.svg#eye' />
        </svg>
      </EyeButton>
    </div>
  );
};
