/* eslint-disable @typescript-eslint/no-empty-function */
import { LoginRequestProps, User, ResetPasswordCredentials } from '../typings';

export type InitialAuthState = {
  isLoading: boolean;
  isAuthenticated: boolean;
  isValidating: boolean;
  user: User | null;
  login: (credentials: LoginRequestProps) => void;
  logout: () => void;
  sendPasswordRecover: (email: string) => void;
  createNewPassword: (credentials: ResetPasswordCredentials) => void;
};

export const initialAuthState: InitialAuthState = {
  isLoading: false,
  isValidating: false,
  user: null,
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
  sendPasswordRecover: () => {},
  createNewPassword: () => {}
};
