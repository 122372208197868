import { createContext, useContext } from 'react';
import { initialAuthState, InitialAuthState } from './state';

const AuthContext = createContext < InitialAuthState >(initialAuthState);

export function useAuth () {
  return useContext(AuthContext);
}

export default AuthContext;
