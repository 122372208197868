import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import ThemeProvider from './theme/ThemeProvider';
import { ChildrenProps } from './typings';
import AppAlertProvider from './AlertContext';

const AppProvider: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <BrowserRouter>
      <AppAlertProvider>
        <AuthProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </AuthProvider>
      </AppAlertProvider>
    </BrowserRouter>
  );
};

export default AppProvider;
