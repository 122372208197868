import { FC, useRef } from 'react';
import styles from './InputDate.module.css';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

type InputDateProps = {
  date: Date | undefined;
  onChange: (date: Date) => void;
}

export const InputDate: FC<InputDateProps> = ({ date, onChange }) => {
  const inputRef = useRef<Flatpickr>(null);

  const onClick = () => {
    inputRef.current?.flatpickr.open();
  };

  const options = {
    enableTime: false,
    dateFormat: 'd M Y'
  };

  return (
    <div className={styles.inputGroup} onClick={onClick}>
      <Flatpickr
        ref={inputRef}
        className={styles.inputField}
        options={options}
        placeholder='Child’s predicted date of birth'
        value={date}
        onChange={([date]) => {
          onChange(date);
        }}
      />
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <use href='/assets/icons/date.svg#date' />
      </svg>
    </div>
  );
};
