import { action, query } from '../helpers/WebApi';
import { LoginRequestProps, AuthenticationResponse, RecoverPasswordResponse, ResetPasswordCredentials, ResetPasswordResponse, ApplyUserCredentials, ApplyUserResponse, WhoAmIResponse } from '../typings';

export const loginAPIRequest = async (credentials: LoginRequestProps) => {
  const res = await action<LoginRequestProps, AuthenticationResponse>('/login', credentials);
  return res;
};

export const whoAmIRequestAPI = async () => {
  const { error, data } = await query<undefined, WhoAmIResponse>('/whoami');
  if (error) {
    throw error;
  }
  return data;
};

export const recoverPasswordApiRequest = async (email: string) => {
  const { data, error } = await action<Pick<LoginRequestProps, 'email'>, RecoverPasswordResponse>('/requestPasswordReset', { email });
  if (error) {
    throw error;
  }
  return data;
};

export const resetPasswordApiRequest = async (credentials: ResetPasswordCredentials) => {
  const { success, error } = await action<ResetPasswordCredentials, ResetPasswordResponse>('/resetPassword', credentials);
  if (error) {
    throw error;
  }
  return success;
};

export const applyUserAPI = async (credentials: ApplyUserCredentials) => {
  const { data, error } = await action<ApplyUserCredentials, ApplyUserResponse>('/apply', credentials);
  if (error) {
    throw error;
  }
  return data;
};
