import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './RequireAuth';

import { routes } from './routes-constants';
import { Roles } from '../typings';

const AdminPage = lazy(() => import('../containers/Admin/AdminPage'));
const UserPage = lazy(() => import('../containers/User/UserPage'));
const AuthenticationPage = lazy(
  () => import('../containers/Authentication/AuthenticationPage')
);
const LandingPage = lazy(() => import('../containers/LandingPage/LandingPage'));
const GenerateBaby = lazy(() => import('../containers/GenerateBaby/GenerateBaby'));

const Routing = () => {
  return (
    <Routes>
      <Route
        path={`/${routes.admin.index}/*`}
        element={
          <RequireAuth allowedRoles={[Roles.admin]}>
            <Suspense fallback={null}>
              <AdminPage />
            </Suspense>
          </RequireAuth>
        }
      />
      <Route
        path={`/${routes.user.index}/*`}
        element={
          <RequireAuth allowedRoles={[Roles.user]}>
            <Suspense fallback={null}>
              <UserPage />
            </Suspense>
          </RequireAuth>
        }
      />
      <Route
        path={`/${routes.authorization.index}/*`}
        element={
          <Suspense fallback={null}>
            <AuthenticationPage />
          </Suspense>
        }
      />
      <Route
        path='/'
        element={
          <Suspense fallback={null}>
            <LandingPage />
          </Suspense>
        }
      />
      <Route
        path='/generate-baby'
        element={
          <Suspense fallback={null}>
            <GenerateBaby />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Routing;
