const API_DOMAIN = process.env.REACT_APP_BASE_URL;

const API_BEARER_TOKEN = (): [string, string] => [
  'authorization',
  `Bearer ${localStorage.getItem('token')}`
];

export const query = async <K extends Record<any, any> | undefined, T>(
  endpoint: string,
  params?: K
): Promise<T> => {
  let uri = `${API_DOMAIN}/api${endpoint}`;

  if (params) {
    const searchParams = new URLSearchParams();
    for (const param in params) {
      const value = params[param];
      searchParams.set(param, value);
    }
    const queryString = searchParams.toString();
    if (queryString) {
      uri += `?${queryString}`;
    }
  }

  const request = new Request(uri, {
    credentials: 'include',
    headers: [['accept', 'application/json'], API_BEARER_TOKEN()]
  });

  return fetch(request).then(async (res) => {
    let payload = null;
    try {
      const text = await res.text();
      payload = text ? JSON.parse(text) : null;
    } catch (err) {
      console.warn(`Response (${res.url}) did not contain valid JSON`);
      return null;
    }

    return payload;
  });
};

export const action = async <K, T>(
  endpoint: string,
  payload: K
): Promise<T> => {
  const uri = `${API_DOMAIN}/api${endpoint}`;

  const request = new Request(uri, {
    method: 'POST',
    credentials: 'include',
    headers: [
      ['accept', 'application/json'],
      ['content-type', 'application/json'],
      API_BEARER_TOKEN()
    ],
    body: JSON.stringify(payload)
  });

  return fetch(request).then(async (res) => {
    let payload = null;

    try {
      const text = await res.text();
      payload = text ? JSON.parse(text) : null;
    } catch (err) {
      console.warn(`Response (${res.url}) did not contain valid JSON`);
      return null;
    }

    return payload;
  });
};

export const multipartAction = async <T>(
  endpoint: string,
  payload: FormData
): Promise<T> => {
  const uri = `${API_DOMAIN}/api${endpoint}`;

  return fetch(uri, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: payload
  }).then(async (res) => {
    let payload = null;

    try {
      const text = await res.text();
      payload = text ? JSON.parse(text) : null;
    } catch (err) {
      console.warn(`Response (${res.url}) did not contain valid JSON`);
      return null;
    }

    return payload;
  });
};

export const downloadAction = async <K extends Record<any, any>, T>(
  endpoint: string,
  params: K
): Promise<T> => {
  let uri = `${API_DOMAIN}/api${endpoint}`;

  if (params) {
    const searchParams = new URLSearchParams();
    for (const param in params) {
      const value = params[param];
      searchParams.set(param, value);
    }
    uri += `?${searchParams}`;
  }

  return fetch(uri, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    responseType: 'blob'
  } as RequestInit & { responseType?: string })
    .then(async (res) => {
      try {
        if (!res.ok) {
          return res.json();
        }

        let filename = '';
        const contentDisposition = res.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = contentDisposition?.match(filenameRegex);
        if (matches && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }

        const blob = await res.blob();

        return {
          file: blob,
          filename
        };
      } catch (err) {
        console.warn(`Response (${res.url}) did not contain valid JSON`);
        return null;
      }
    });
};
