export const adminRoutes = {
  index: 'admin',
  dashboard: 'dashboard',
  usersProfiles: 'users-profiles',
  photoRequests: 'photo-requests'
};

export const userRoutes = {
  index: 'user',
  dashboard: 'dashboard',
  docs: 'documents'
};

export const authorization = {
  index: 'auth',
  login: 'login',
  recover: 'recover',
  resetPassword: 'reset-password',
  signUp: 'signup'
};

export const routes = {
  admin: adminRoutes,
  user: userRoutes,
  authorization
};
